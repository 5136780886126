.ui.inverted.text.menu.NavBar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 767px) {
    .ui.inverted.text.menu.NavBar .NavBar-container {
      margin-left: 1em!important;
      margin-right: 1em!important;
    }
  }