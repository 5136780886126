.ui.inverted.segment.HomePage-masthead {
  background: transparent;
}

.ui.text.container.HomePage-masthead-container {
  max-width: 900px!important;
  text-shadow: 20px 20px 120px #000;
}

.ui.inverted.segment.HomePage-masthead .grid {
  margin-top: 4em;
  margin-left: auto!important;
  margin-right: auto!important;
  margin-bottom: 2em;
  max-width: 1400px;
}