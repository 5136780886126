.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App-content,
.App-content-fullpage {
    flex: 1 0 auto;
    min-height: 750px;
}

.App-content-fullpage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("https://res.cloudinary.com/van-gelder-studio/image/upload/f_auto/v1618170625/van-gelder-common/studio-full-bw-sized_dieahp.jpg");
}