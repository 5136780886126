.ui.inverted.segment.FullPage-masthead {
    background: transparent;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 767px) {
  .ui.inverted.segment.FullPage-masthead .FullPage-container {
    width: 100%!important;
  }
}