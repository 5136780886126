.ui.inverted.segment.MiniMasthead-background {
  background-size: cover;
  background-image: url('https://res.cloudinary.com/van-gelder-studio/image/upload/e_blur:600/q_auto/f_auto/v1618170546/van-gelder-common/trane-quartet_vxzdqw.jpg');

  display: flex;
  align-items: center;
  min-height: 200px;
}

@media only screen and (max-width: 767px) {
  .ui.inverted.segment.MiniMasthead-background .MiniMasthead-container {
    width: 100%!important;
  }
}