.ui.container.EventPage-embed-container {
    max-width: 90%!important;
}

.ui.container.EventPage-headline-container {
    max-width: 1000px!important;
}

@media only screen and (min-width: 768px) {
    .ui.container.EventPage-button-container {
        max-width: 400px!important;
    }
}