.StripeElement {
    padding: 0.67em 1em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 1px 5px rgba(0, 0, 0, 0.08);
}

fieldset.CheckoutForm-cardholder {
    padding: 0;
    border: 1px solid rgba(34,36,38,.15);
    margin-bottom: 2em;
    border-radius: 0.28571429rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 1px 5px rgba(0, 0, 0, 0.08);
}

fieldset.CheckoutForm-cardholder .ui.fluid.input > input {
    border: none;
}

fieldset.CheckoutForm-cardholder .ui.fluid.input > input:first-child {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}